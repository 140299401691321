import base from './index'
let axios = base.axios
let baseUrl = base.baseUrl
let token = "1232316456231sfsfsdff"

// 获取好友
export const getFriend = params => {
    return axios({
        method: 'post',
        baseURL: `${baseUrl}/friend/friendList`,
        data: params,
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json;charset=UTF-8' }
    }).then(res => res.data)
}

// 获取聊天信息
export const getChatMsg = params => {
    // console.log("baseUrl", baseUrl + '/friend/chatMsg')
    return axios({
        method: 'post',
        baseURL: `${baseUrl}/friend/chatMsg`,
        data: params,
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json;charset=UTF-8' }
    }).then(res => res.data)
}

//AI获取模型列表
export const getTxtPicModel = params => {
    return axios({
        method: 'get',
        baseURL: `${baseUrl}/txt2pic/models`,
        data: params,
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json;charset=UTF-8' }
    }).then(res => res.data)
}

//文生图
export const getTxtPic = params => {
    // console.log("baseUrl", baseUrl + '/txt2pic/generate')
    return axios({
        method: 'post',
        baseURL: `${baseUrl}/txt2pic/generate`,
        data: params,
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json;charset=UTF-8' }
    }).then(res => res.data)
}

//图生图
export const getPic = params => {
        return axios({
            method: 'post',
            baseURL: `${baseUrl}/pic2pic/generate`,
            data: params,
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json;charset=UTF-8' }
        }).then(res => res.data)
    }
    //训练模型
export const getLoraTrain = params => {
    return axios({
        method: 'post',
        baseURL: `${baseUrl}/lora/train`,
        data: params,
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json;charset=UTF-8' }
    }).then(res => res.data)
}

//删除模型
export const deleteModel = params => {
    return axios({
        method: 'delete',
        baseURL: `${baseUrl}/lora/models/${params.model_id}`,
        data: params,
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json;charset=UTF-8' }
    }).then(res => res.data)
}

//使用lora模型
export const lora_generate = params => {
    return axios({
        method: 'post',
        baseURL: `${baseUrl}/lora/generate`,
        data: params,
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json;charset=UTF-8' }
    }).then(res => res.data)
}

//使用lora模型
export const getModel = params => {
    return axios({
        method: 'get',
        baseURL: `${baseUrl}/lora/models`,
        data: params,
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json;charset=UTF-8' }
    }).then(res => res.data)
}

//获取聊天记录  /api/histories
export const getHistories = params => {
    return axios({
        method: 'get',
        baseURL: `${baseUrl}/histories?job_type=` + params.job_type + `&reverse=true`,
        data: params,
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json;charset=UTF-8' }
    }).then(res => res.data)
}

//删除聊天记录  /api/histories/{job_id}
export const delHistories = params => {
    return axios({
        method: 'delete',
        baseURL: `${baseUrl}/histories/` + params.job_id,
        data: params,
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json;charset=UTF-8' }
    }).then(res => res.data)
}