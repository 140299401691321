import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import VueRouter from 'vue-router'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index'
import "./mock/index.js"
import "../src/util/rem.js"


import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

Vue.use(VueRouter)
Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')