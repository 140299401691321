<template>
  <div class="home">
    <div class="top">
      <div class="top_left">
        <img class="top_image" src="@/assets/img/top_image.png" style="">
        <span class="top_text">商汤科技Sensechat_edu私有化部署定制版v1.0</span>
      </div>
      <div class="top_right" @click="help">
        <img class="help_image" src="@/assets/img/help.png" style="">
        <span class="help_text">用户指南</span>
      </div>
    </div>
    <el-container height="100%">
      <el-aside width="115px">
        <Nav></Nav>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Nav from "../components/Nav.vue";
export default {
  name: "App",
  components: {
    Nav,
  },
  methods:{
    help(){
      this.$router.push('/Help');
    }
  }
};
</script>

<style lang="scss" scoped>

.top_right{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.help_text{
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  margin-right: 40px;
  margin-left: 5px;
}
.help_image{
  width: 21px;
  height: 21px;
}

.home {
  display: flex;
  flex-direction: column;
  // width: 90vw;
  // height: 90vh;
  // width: 100vw;
  height: 103%;
  //min-height: calc(100vh - 0.9rem);
//  background: rgba(16, 15, 17, 1);
  // border-radius: 15px;
    // border-radius: 0px;
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
}
.top_image{
  width: 60px;
  height: 32px;
  margin-top:20px;
  margin-left: 20px;
  margin-right:24px
}
.top_text{
    font-size: 21px;
    font-weight: 400;
    letter-spacing: 0px;
    // line-height: 24px;
    color: rgba(255, 255, 255, 0.8);
    text-align: left;
    vertical-align: top;
}
.top{
  display:flex;
  flex-direction: row;
  align-content: center;
  text-align: center;
  justify-content: space-between;
  height: 65px;
  line-height: 74px;
  background:rgba(19, 5, 51, 1);
}
.el-container{
// background: rgba(16, 15, 17, 1);
background-image: url("@/assets/img/chat-image.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
</style>