<template>
  <div class="chatHome">

    <div class="chatLeft" v-if="is_lora">

        <div class="top_mian1">
           <div class="chat_image">
              <img src="@/assets/img/image_button.png" alt=""  style="width: 40px;height: 35.56px;"/>
              <span class="image_text">点击/拖拽 上传图片</span> 
           </div>
           <div>
             <scf-input-textarea :value="inputValue" @change="onInputValueChange" :placeholder="image_placeholder" />     
           </div>
           <div class="optimize">
              <div class="optimize—left">
                 <span>优化提示词1</span>
                 <img class="optimize—image" src="@/assets/img/optimize.png" style="">
              </div>
              <el-switch
                style="display: block"
                v-model="value1"
                active-color="rgba(121, 72, 234, 1)"
                inactive-color="rgba(128, 128, 128, 1)">
              </el-switch>
            </div>
            <div class="optimize" style="margin-top:8px">
              <div class="optimize—left">
                 <span>反向提示词</span>
                 <img class="optimize—image" src="@/assets/img/optimize.png" style="">
              </div>
              <el-switch
                style="display: block"
                v-model="value2"
                active-color="rgba(121, 72, 234, 1)"
                inactive-color="rgba(128, 128, 128, 1)">
              </el-switch>
            </div>
        </div>

        <div class="top_mian1" style="margin-top:15px">
            <span class="set_title"><img class="base_image" src="@/assets/img/base_images.png" style=""></span>
            <div class="optimize">
              <div class="optimize—left" >
                 <span>比例</span>
                 <div>
                  <el-button type="primary" size="mini" round icon="el-icon-news" style="background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);">1:1</el-button>
                  <el-button type="primary" size="mini" round icon="el-icon-news" style="background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);">4:3</el-button>
                  <el-button type="primary" size="mini" round icon="el-icon-news" style="background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);">16:9</el-button>
                  <el-button type="primary" size="mini" round  style="background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);">自定义</el-button>
                 </div>
              </div>
            </div>
             <div class="optimize" style="margin-top:12px">
              <div class="optimize—left" >
                 <span>画质</span>
                 <div>
                  <el-button type="primary" size="mini" round  style="width: 84px;background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);">标清（540）</el-button>
                  <el-button type="primary" size="mini" round  style="width: 84px;background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);">高清（1080）</el-button>
                 </div>
              </div>
            </div>
             <div class="optimize" style="margin-top:12px">
              <div class="optimize—left" >
                 <span>数量</span>
                 <div>
                  <el-button type="primary" size="mini" round  style="background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);width: 84px;">1</el-button>
                  <el-button type="primary" size="mini" round  style="background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);width: 84px;">2</el-button>
                 </div>
              </div>
            </div>
        </div>

        <div class="top_mian1" style="margin-top:15px">
            <span class="set_title"><img class="base_image" src="@/assets/img/upset_images.png" style=""></span>
           <div class="optimize">
              <div class="optimize—step" >
                 <span>种子数</span>
                 <div style="display:flex;  align-items: center;">                    
                    <div class="step_number_">
                      <input v-model="step_num" class="step_sz" style="width:378px;margin-top:10px">
                    </div>                     
                 </div>
              </div>
            </div>
             <div class="optimize" style="margin-top:12px">
              <div class="optimize—step" >
                <div>
                   <span>步   数</span>
                  <img class="optimize—image" src="@/assets/img/optimize.png" style="">
                </div>
                
                 <div style="display:flex;  align-items: center;">
                    <div style="width:280px;"> 
                      <el-slider v-model="value1"></el-slider>
                    </div>
                    <div class="step_number">
                      <input v-model="step_num" class="step_sz">
                    </div>                     
                 </div>
              </div>
            </div>
             <div class="optimize" style="margin-top:12px">
              <div class="optimize—step" >
                <div>
                    <span>约束强度控制</span>
                    <img class="optimize—image" src="@/assets/img/optimize.png" style="">
                </div>
                
                 <div style="display:flex;  align-items: center;">
                    <div style="width:280px;"> 
                      <el-slider v-model="value22"></el-slider>
                    </div>
                    <div class="step_number">
                      <input v-model="step_num" class="step_sz">
                    </div>                     
                 </div>
              </div>
            </div>
        </div>
     
      <div class="title">
        <!-- <h1>大猫聊天室2</h1> -->
      </div>

    

      
    </div>

    <div class="chatRight" v-if="is_lora">
      <!-- <router-view></router-view> -->
      <div v-if="showChatWindow">
        <ChatWindow
          :frinedInfo="chatWindowInfo"
          @personCardSort="personCardSort"
        ></ChatWindow>
      </div>
      <div class="showIcon" v-else>
        <span class="iconfont icon-snapchat"></span>
        <!-- <img src="@/assets/img/snapchat.png" alt="" /> -->
      </div>
    </div>


    <div class="lora_empty" v-if="!is_lora"  @click="router_level">
                
      <img src="@/assets/img/empty_lora.png" style="width: 474px;height: 359px;">

    </div>


    <!-- <el-col :span="4"><div class="grid-content bg-purple"></div></el-col> -->
  </div>
</template>

<script>
import PersonCard from "@/components/PersonCard.vue";
import ChatWindow from "./chatwindow.vue";
import PaperTextArea from '@/components/paper-textarea.vue';
import { getModel } from "@/api/getData";
import router from "@/router";
export default {
  name: "App",
  components: {
    PersonCard,
    ChatWindow,
    'scf-input-textarea': PaperTextArea,
  },
  data() {
    return {
      pcCurrent: "",
      personList: [],
      showChatWindow: true,
      chatWindowInfo: {},
      inputValue: '',
      value1:false,
      value2:true,
      value22:10,
      step_num:1,//步   数
      image_placeholder:"请输入提示词，描述你的创意。\n如生成一副赵无极风格的抽象油画（文字部分待确认)",
      is_lora:false
    };
  },
  mounted() {
    getModel().then(res=>{
        if(res.ok){
          if( res.data.length>0){
            this.$router.push({
              name: "LoraList",
            }, () => {});
          }
        }
      })
  },
  methods: {
    router_level(){
      this.$router.push({
            name: "LoraApply",
          }, () => {});
    },
     formatTooltip(val) {
        return val / 100;
      },
    onInputValueChange(value) {
      this.inputValue = value;
    },
    clickPerson(info) {
      this.showChatWindow = true;
      this.chatWindowInfo = info;
      this.personInfo = info;
      this.pcCurrent = info.id;
    },
    personCardSort(id) {
      if (id !== this.personList[0].id) {
        let nowPersonInfo;
        for (let i = 0; i < this.personList.length; i++) {
          if (this.personList[i].id == id) {
            nowPersonInfo = this.personList[i];
            this.personList.splice(i, 1);
            break;
          }
        }
        this.personList.unshift(nowPersonInfo);
      }
    },
  },
};
</script>
<style lang="scss" scoped>

.lora_empty{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 68vh;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}
.image_text{
  font-size: 16px;
font-weight: 400;
letter-spacing: 0px;
line-height: 21.95px;
color: rgba(128, 128, 128, 1);
text-align: left;
vertical-align: top;
margin-left: 15px;
}
.chat_image{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 390px;
  height: 132px;
  opacity: 1;
  border-radius: 10px;
  
  margin: 0px 20px 15px 20px;
  border: 2px dashed rgba(42, 41, 46, 1);

}
.step_sz{
  width: 80px;
  height: 30px;
  opacity: 1;
  border-radius: 6px;
  background: rgba(42, 41, 46, 1);
  color: rgba(255, 255, 255, 1);
  border: none; /* 去除边框 */
  outline: none; /* 去除焦点时的轮廓 */
  // color: #fff;
  text-align: center;
}
.step_number_{
  width: 160px;
}
.step_number{
  margin-left: 15px;
  width: 60px;
}
.block{
  widows: 120px;
}
.set_title{
  margin-left:20px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(229, 229, 229, 1);
}
.optimize{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-top: 10px;
  margin-right: 20px;
}
.optimize—left{
   display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}
.optimize—left span{
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21.95px;
  color: rgba(229, 229, 229, 1);
  margin-right:8px;
}
.optimize—step{
   display: flex;
  flex-direction: column;
  // align-items: center;
  // text-align: center;
}
.optimize—step span{
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21.95px;
  color: rgba(229, 229, 229, 1);
  margin-right:8px;
}
.optimize—image{
  width: 12px;
  height: 12px;
}
.top_mian1{
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 430px;
  padding-bottom: 10px;
  padding-top: 12px;
  opacity: 1;
  border-radius: 12px;
  background: rgba(24, 23, 26, 1);
}
.base_image{
  width: 78.45px;
  height: 33.22px;
}
.chatHome {
  // margin-top: 20px;
  display: flex;
  .chatLeft {
    width: 430px;
    margin-top: -6px;
    .title {
      color: #fff;
      padding-left: 10px;
    }
    .online-person {
      margin-top: 100px;
      .onlin-text {
        padding-left: 10px;
        color: rgb(176, 178, 189);
      }
      .person-cards-wrapper {
        padding-left: 10px;
        height: 65vh;
        margin-top: 20px;
        overflow: hidden;
        overflow-y: scroll;
        box-sizing: border-box;
        &::-webkit-scrollbar {
          width: 0; /* Safari,Chrome 隐藏滚动条 */
          height: 0; /* Safari,Chrome 隐藏滚动条 */
          display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
        }
      }
    }
  }

  .chatRight {
    flex: 1;
    padding-right: 30px;
    .showIcon {
      position: absolute;
      top: calc(50% - 150px); /*垂直居中 */
      left: calc(50% - 50px); /*水平居中 */
      .icon-snapchat {
        width: 300px;
        height: 300px;
        font-size: 300px;
        // color: rgb(28, 30, 44);
      }
    }
  }
}
</style>