<template>
  <div class="home">
    <div class="top">
        <img class="topimage" src="@/assets/img/top_image.png" style="">
        <span class="top_text">商汤科技Sensechat_edu私有化部署定制版v1.0</span>
    </div>
    <div class="el-container" height="100%">
      <div class="main">
            <div class="login_left">
               <img class="top_image" src="@/assets/img/login.png" style="">
            </div>
            <div class="login_right">
                <div class="">
                    <img class="bg" src="@/assets/img/show_bg.png">
                   <img class="image" src="@/assets/img/login_right.png" style="">
                </div>
                 <div>
                    <img class="do_login" src="@/assets/img/login_do.png" v-on:click="showMsg">
                 </div>
                 <div style="display:block;margin-top:20px">
                    <img class="incon_image" src="@/assets/img/icon.png">                    
                    <span class="agreement">已阅读并同意 隐私政策/AI功能使用须知</span>
                 </div>
            </div>             
        </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "App",
  components: {

  },
  methods:{
        showMsg(){
        this.$router.push('/ChatHome');
}
  }
};
</script>

<style lang="scss" scoped>
.el-aside{
  display: none !important;
}
.home {
  // width: 90vw;
  height: 100%;
  width: 100%;
  // height: 100%;
    background: rgba(0, 0, 1, 1);
  // border-radius: 15px;
   border-radius: 0px;
  position: absolute;
  left: 50%;
  top: 51%;
  transform: translate(-50%, -50%);
}

.agreement{
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(226, 214, 255, 0.47);
  text-align: left;
}
.topimage{
  width: 60px;
  height: 32px;
  margin-top:20px;
  margin-left: 20px;
  margin-right:24px
}
.top_text{
    font-size: 21px;
    font-weight: 400;
    letter-spacing: 0px;
    // line-height: 24px;
    color: rgba(255, 255, 255, 0.8);
    text-align: left;
    vertical-align: top;
}
.top{
  display:flex;
  flex-direction: row;
  align-content: center;
  text-align: center;
  height: 65px;
  line-height: 74px;
  background:rgba(19, 5, 51, 1);
}

.main{
    display: flex;
    flex-direction: row;
    align-content: center;
    text-align: center;
    background: rgba(0, 0, 1, 1);
    width: 100%;
    height: 100%;
 }
 .bg{
    position: absolute;
    width: 268px;
    height: 308.67px;
    margin-top: -178px;
    margin-left: 112px;
 }
 .login_left{
    display: flex;
 }
 .login_right{
    display: flex;
    flex-direction: column;
    justify-content: center;
   //  margin-left: -40px;
   //  margin-top: -30px;
    align-content: center;
    text-align: center;
   //  width: 100vh;
 }
 .image{
    position: relative;  
    width: 276px;
    height: 58px;
    z-index: 9999;
 }
 .do_login{
    margin-top: 49px;
    width: 230px; 
    height: 51px;  
 }
 .incon_image{
   margin-right: 10px;
   width: 12px;
   height: 12px;
 }
.login_text{
    font-size: 48px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 57.6px;
    color: rgba(105, 41, 255, 1);
    text-align: left;
    vertical-align: top;
    margin-top: auto;
}
 .top_image{
    margin-top: 115px;
    width: 1177px;
    min-height: 663px;
 }
 .el-main {
    padding: 0px !important;
}

</style>