var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-window"},[_c('div',{staticClass:"top",staticStyle:{"border-radius":"12px","background":"rgba(24, 23, 26, 1)","border":"1.4px solid rgba(56, 56, 56, 1)"}},[_vm._m(0),_c('div',{staticStyle:{"display":"flex","margin-top":"5px","margin-left":"20px"}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('van-uploader',{attrs:{"file-list":_vm.fileList,"bind:after-read":"afterRead"}}),_vm._m(6)],1)]),_c('div',{staticClass:"botoom"},[_c('div',{ref:"chatContent",staticClass:"chat-content"},[_vm._l((_vm.chatList),function(item){return (_vm.images)?_c('div',{key:item.id,staticClass:"chat-wrapper"},[_c('div',{staticClass:"chat-friend"},[_c('div',{staticClass:"info-time"},[_c('span',{staticClass:"send_time"},[_vm._v(_vm._s(item.time))]),_vm._m(7,true)]),_c('div',{staticClass:"info-time"},[_c('span',[_vm._v(_vm._s(item.send_msg))])]),_c('div',{staticClass:"chat-img"},_vm._l((item.msg),function(images){return _c('div',{key:images,staticClass:"word-file"},[_c('img',{staticStyle:{"width":"269px","height":"202px","padding-right":"10px"},attrs:{"src":images}})])}),0)])]):_vm._e()}),(!_vm.images)?_c('div',{staticClass:"chat-empty"},[_vm._m(8)]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"model_select"},[_c('img',{staticStyle:{"width":"127px","height":"32px"},attrs:{"src":require("@/assets/img/model_select1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"model1 monde_active"},[_c('span',{staticClass:"model_title"},[_vm._v("线条学习")]),_c('span',{staticClass:"model_text"},[_vm._v("提取轮廓进行重绘")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"model2"},[_c('span',{staticClass:"model_title"},[_vm._v("亮度控制")]),_c('span',{staticClass:"model_text"},[_vm._v("匹配参考图亮度")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"model3"},[_c('span',{staticClass:"model_title"},[_vm._v("姿势迁移")]),_c('span',{staticClass:"model_text"},[_vm._v("提取骨骼动作")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"model4"},[_c('span',{staticClass:"model_title"},[_vm._v("深度迁移")]),_c('span',{staticClass:"model_text"},[_vm._v("保留结构和层次细节")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"model5"},[_c('span',{staticClass:"model_title"},[_vm._v("线稿学习")]),_c('span',{staticClass:"model_text"},[_vm._v("局部细节更加丰富")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"model_submit"},[_c('img',{staticStyle:{"width":"132px","height":"40px"},attrs:{"src":require("@/assets/img/model_submit.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"send_image"},[_c('img',{staticStyle:{"width":"16px","height":"16px"},attrs:{"src":require("@/assets/img/download.png")}}),_c('img',{staticStyle:{"width":"1px","height":"12px"},attrs:{"src":require("@/assets/img/middle.png")}}),_c('img',{staticStyle:{"width":"16px","height":"16px"},attrs:{"src":require("@/assets/img/deal_msg.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"empty_info"},[_c('img',{staticStyle:{"width":"120px","height":"120px"},attrs:{"src":require("@/assets/img/empty.png"),"alt":""}}),_c('span',{staticClass:"empty_text"},[_vm._v("让无处不在的灵感随时落地吧～")])])
}]

export { render, staticRenderFns }