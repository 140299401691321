import VueRouter from 'vue-router'

import Home from '../view/Index.vue'
import Help from '../view/help.vue'
import ChatHome from '../view/pages/chatHome/index.vue'
import ChatImage from '../view/pages/chatImage/index.vue'
import Lora from '../view/pages/lora/index.vue'
import LoraApply from '../view/pages/lora/apply.vue'
import LoraList from '../view/pages/lora/list.vue'
import UseLora from '../view/pages/useLora/index.vue'
export default new VueRouter({
    routes: [{
            path: "/",
            redirect: "/Home",
        }, {
            path: "/Help",
            name: "/Help",
            component: Help,
        },
        {
            path: "/Home",
            name: "Home",
            component: Home,
        },
        {
            path: "/ChatHome",
            name: "ChatHome",
            component: ChatHome,
        },
        {
            path: "/ChatImage",
            name: "ChatImage",
            component: ChatImage,
        },
        {
            path: "/Lora",
            name: "Lora",
            component: Lora,
        },
        {
            path: "/LoraApply",
            name: "LoraApply",
            component: LoraApply,
        },
        {
            path: "/LoraList",
            name: "LoraList",
            component: LoraList,
        },
        {
            path: "/UseLora",
            name: "UseLora",
            component: UseLora,
        },

    ]
})