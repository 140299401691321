<template>
  <div class="chatHome">
    <div class="chatLeft">

        <div class="top_mian1">
           <div class="chat_image">

            <div v-if="images" style="margin-left: 20px; display: flex; flex-direction: row;;width: 100%;">
              <img :src="images" alt=""  style="width: 115px;height: 92px;"/>
              <div style="display: flex; flex-direction: column;justify-content: space-between;width: 100%;">
                <span class="image_text">{{fileName }}</span> 
                <div style="display: flex; justify-content: end;margin-right: 20px;cursor: pointer;" @click="deleteDefault()">
                  <img src="@/assets/img/deal_msg.png" alt=""   style="width: 16px;height: 16px;">
                </div>               
              </div>
             
            </div>
              <el-upload v-else
                class="upload-dragger"
                drag
                name="file"
                accept=".png,.jpg,.jpeg,.bmp" 
                action="/api/upload_picture?scene=lora"
                :on-preview="handlePreview"
                :on-success="handleSuccess"
                >

                <!-- <i class="el-icon-upload"></i> -->
                <img  src="@/assets/img/default_iamge.png" class="avatar">

                <div class="el-upload__text" style="font-size: 16px; margin-left: 10px;">点击/拖拽 <em style="color: rgba(204, 204, 204, 1);">上传图片</em></div>
              </el-upload>
           </div>
           <div>
             <scf-input-textarea :value="ForwardValue"  @change="onInputValueChange" :placeholder="image_placeholder" :msgtyp="1" :maxlength="100"/>     
           </div>

           <div style="margin-top: 16px;"  v-if="IsReverse">
             <scf-input-textarea :value="ReverseValue" @change="onInputValueChange" :placeholder="image_placeholder_f" :msgtyp="2" :IsReverse="IsReverse" :maxlength="20"/>     
           </div>

           <div class="optimize" style="margin-top:16px">
              <div class="optimize—left">
                 <span>优化提示词</span>
                 <el-popover offset="10"   placement="top-start"    title=""   popper-class="popoverStyle"     width="180"    trigger="hover"    content="精确地控制AI生成的图像内容、风格和氛围，使其更符合用户的期望和需求">
                 <img class="optimize—image" src="@/assets/img/optimize.png" style="" slot="reference">
                 </el-popover>
              </div>
              <el-switch
                style="display: block"
                v-model="IsForward"
                active-color="rgba(121, 72, 234, 1)"
                inactive-color="rgba(128, 128, 128, 1)">
              </el-switch>
            </div>
            <div class="optimize" style="margin-top:16px"  >
              <div class="optimize—left">
                 <span>反向提示词</span>
                 <el-popover class="labelList-popover" offset="10"   popper-class="popoverStyle"   placement="top-start"    title=""    width="180"    color="red" trigger="hover"    content="包含一些负面的、不希望出现在最终图像中的描述性内容">
                 <img class="optimize—image" src="@/assets/img/optimize.png" style="" slot="reference">
                 </el-popover>
              </div>
              <el-switch
                style="display: block"
                v-model="IsReverse"
                active-color="rgba(121, 72, 234, 1)"
                inactive-color="rgba(128, 128, 128, 1)">
              </el-switch>
            </div>
        </div>

        <div class="top_mian1" style="margin-top:15px">
            <span class="set_title"><img class="base_image" src="@/assets/img/base_images.png" style=""></span>
            <div class="optimize">
              <div class="optimize—left">
                 <span style="display:flex;flex-direction: row;width:30px;font-size: 12px;">比例</span>
                 <div style="display:flex;width:100%;word-wrap: break-word;">
                  <el-button type="primary" size="mini" round :class="[scale=='1080*1080'?'my-custom-class':'' ]"  @click="scaleClick('1080*1080')" style="width: 25%; height: 36px; background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);">1080*1080</el-button>
                  <el-button type="primary" size="mini" round :class="[scale=='1080*810'?'my-custom-class':'' ]"  @click="scaleClick('1080*810')" style="width: 25%; height: 36px; background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);">1080*810</el-button>
                  <el-button type="primary" size="mini" round :class="[scale=='1280*720'?'my-custom-class':'' ]"  @click="scaleClick('1280*720')" style="width: 25%; height: 36px; background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);">1280*720</el-button>
                  <el-button type="primary" size="mini" round :class="[scale==4?'my-custom-class':'' ]"  @click="scaleClick(4)" style="width: 25%; height: 36px; background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);">自定义</el-button>
                 </div>
              </div>
            </div>

            
            <div class="optimize" style="margin-top:12px" v-if="scale==4">
              <div class="optimize—left" style="display: flex; flex-direction: row;width:100%">
                 
                 <div class="make_class">
                  <div style="display: flex;margin-top: 10px;">
                    <span>宽</span>
                      <el-popover  offset="10"  placement="top-start"    title=""    width="50"  popper-class="popoverStyle"   trigger="hover"    content="数值不超过2000">                    
                      <img class="optimize—image" src="@/assets/img/optimize.png" style="" slot="reference">
                      </el-popover>
                  </div>                  
                  <el-input v-model="made_width"   @blur.native.capture="validateNumber(1)" placeholder="请输入像素值"></el-input>
                </div> 

                <div class="make_class">
                  <div style="display: flex;margin-top: 10px;margin-left: 15px;">
                    <span>高</span>
                      <el-popover  offset="10"  placement="top-start"    title=""    width="50"  popper-class="popoverStyle"   trigger="hover"    content="数值不超过2000">                    
                      <img class="optimize—image" src="@/assets/img/optimize.png" style="" slot="reference">
                      </el-popover>
                  </div>                  
                  <el-input v-model="made_hight"  @blur.native.capture="validateNumber(2)"   placeholder="请输入像素值"></el-input>
                </div> 

              </div>
            </div>


             <div class="optimize" style="margin-top:12px" v-if="scale!=4">
              <div class="optimize—left" >
                 <span style="display:flex;flex-direction: row;width:30px">画质</span>
                 <div style="display:flex;width:100%">
                  <!-- <el-button type="primary" size="mini" round  style="width: 50%;height: 36px; background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);" :class="[quality==540?'my-custom-class1':'' ]" @click="QualityClick(540)">标清（540）</el-button> -->
                  <el-button type="primary" size="mini" round  style="width: 50%;height: 36px;  background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);" :class="[quality==1080?'my-custom-class1':'' ]" @click="QualityClick(1080)">高清（1080）</el-button>
                 </div>
              </div>
            </div>
             <div class="optimize" style="margin-top:12px">
              <div class="optimize—left" >
                 <span style="display:flex;flex-direction: row;width:30px">数量</span>
                 <div>
                  <el-button type="primary" size="mini" round  style="background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);width: 84px;height: 36px; " :class="[quantity==1?'my-custom-class':'' ]" @click="QuantityClick(1)">1</el-button>
                  <el-button type="primary" size="mini" round  style="background-color: rgba(42, 41, 46, 1);border: 0.8px solid rgba(42, 41, 46, 1);width: 84px;height: 36px; " :class="[quantity==2?'my-custom-class':'' ]" @click="QuantityClick(2)">2</el-button>
                 </div>
              </div>
            </div>
          </div>

          <div class="top_mian1" style="margin-top:15px">
            <span class="set_title"><img class="base_image" src="@/assets/img/upset_images.png" style=""></span>
           
            <div class="optimize">
              <div class="optimize—step" >
                <div>
                  <span>种子数</span>
                  <el-popover    placement="top-start"    title=""    width="230"  popper-class="popoverStyle"   trigger="hover"    content="固定一个种子数，会在下次生成图片时最大限度的进行还原，降低一定的随机性。留空会随机产生一个种子。">                    
                  <img class="optimize—image" src="@/assets/img/optimize.png" style="" slot="reference">
                  </el-popover>
                </div>                
                 <div style="display:flex;  align-items: center;width:100%">                    
                    <div class="step_number_">
                      <el-input v-model="seed_num" class="step_sz" style="width:100% !important ;margin-top:10px" @blur.native.capture="validateSeedNum()" ></el-input>
                    </div>                     
                 </div>
              </div>
            </div>

             <div class="optimize" style="margin-top:12px">
              <div class="optimize—step" >
                <div>
                    <span>步   数</span>
                    <el-popover    placement="top-start"    title=""    width="230"  popper-class="popoverStyle"   trigger="hover"    content="更高的步数需要更多的计算时间。但高步数并不一定意味着更好的结果。如果迭代步数过少，会降低生成图像的质量。预设好通用的步数20。（仅支持10的倍数，并不大于100。）">   
                      <img class="optimize—image" src="@/assets/img/optimize.png" style="" slot="reference">
                    </el-popover>
                </div>
                
                 <div style="display:flex;  align-items: center;">
                    <div style="width:75%;"> 
                      <el-slider class="custom-slider" v-model="step_num" :min="10" :max="100" :step="10"></el-slider>
                    </div>
                    <div class="step_number">
                      <input v-model="step_num" class="step_sz" disabled>
                    </div>                     
                 </div>
              </div>
            </div>

             <div class="optimize" style="margin-top:12px">
              <div class="optimize—step" >
                <div>
                    <span>约束强度控制</span>
                    <el-popover    placement="top-start"    title=""    width="220"    trigger="hover"  popper-class="popoverStyle"    content="文本引导强度越高，则提示词文本对画面的影响越强 (并不大于10)">   
                    <img class="optimize—image" src="@/assets/img/optimize.png" style="" slot="reference">
                  </el-popover>
                </div>
                
                 <div style="display:flex;  align-items: center;">
                    <div style="width:75%;"> 
                      <el-slider class="custom-slider" v-model="constraint_strength" :min="1" :max="10"></el-slider>
                    </div>
                    <div class="step_number">
                      <input v-model="constraint_strength" class="step_sz" disabled>
                    </div>                     
                 </div>
              </div>
            </div>

        </div>  

    

      
    </div>
    <div class="chatRight">
      <!-- <router-view></router-view> -->
      <div v-if="showChatWindow">
        <ChatWindow
          :frinedInfo="chatWindowInfo"
          ref="chatContent"
          @personCardSort="personCardSort"
        ></ChatWindow>
      </div>
      <div class="showIcon" v-else>
        <span class="iconfont icon-snapchat"></span>
        <!-- <img src="@/assets/img/snapchat.png" alt="" /> -->
      </div>
    </div>
    <!-- <el-col :span="4"><div class="grid-content bg-purple"></div></el-col> -->
  </div>
</template>

<script>
import PersonCard from "@/components/PersonCard.vue";
import ChatWindow from "./chatwindow.vue";
import PaperTextArea from '@/components/paper-textarea.vue';
import { getPic } from "@/api/getData";
export default {
  name: "App",
  components: {
    PersonCard,
    ChatWindow,
    'scf-input-textarea': PaperTextArea,
  },
  data() {
    return {
      pcCurrent: "",
      personList: [],
      ForwardValue: '', //优化提示词
      ReverseValue: '', //反向提示词
      showChatWindow: true,
      chatWindowInfo: {},
      IsForward:false, //优化提示词 开关
      IsReverse:false, //反向提示词 开关
      quality:1080, //画质
      quantity:1, //数量
      seed_num:'', //种子数
      step_num:20,//步   数
      constraint_strength:3,//约束强度控制
      scale:"1080*1080",
      custom_scale:'',
      image_placeholder:"请输入提示词，描述你的创意。\n如生成一副赵无极风格的抽象油画（文字部分待确认)",
      image_placeholder_f:"请输入提示词，不希望出现的内容。\n如\"红衣服，皮鞋\"（文字部分待确认)",
      messages:[],//聊天记录
      picItem:{},
      images:'',
      fileName:'',
      made_width:"",
      made_hight:""

    };
  },
  mounted() {    
    this.Chatlist();
  },
  methods: {
    validateSeedNum(type){
      let seed_num= this.seed_num
      seed_num =  seed_num.replace(/\D/g, '');
      if (seed_num <= 10 ) {
        seed_num = 10;
      } else if(seed_num > 2147483637  ) {
        seed_num = 2147483637;
      } 
      this.seed_num=seed_num
    },
    validateNumber(type){
      let events 
    if(type==1){
      events =this.made_width
    }else{
      events =this.made_hight
    }
      const value=  events.replace(/\D/g, '');
      let number=''
      if (value <= 500 ) {
         number = 500;
      } else if(value > 2000  ) {
         number = 2000;
      } else{
        number=value
      }
      if(type==1)  this.made_width=number
      if(type==2)  this. made_hight=number
      },
    deleteDefault(){
         this.images=''
         this.images_name=''
         this.picItem={}
    },
     //获取本地信息
     Chatlist(){
      // localStorage.removeItem('chatHistory');
      const chatHistory = localStorage.getItem('chatImageHistory');
      if (chatHistory) {
        try {
          this.messages = JSON.parse(chatHistory);
        } catch (e) {
          // localStorage.removeItem('chatHistory');
        }
      }
    },
    handlePreview(events){
    },
    handleSuccess(events){
      if(events.ok){
        this.images=events.data.url
        this.picItem=events.data                
        this.fileName =  this.images.match(/[^\\/]*$/)[0];

      }else{
        this.$message({
          message: events.msg,
          type: 'warning'
        });
        return        
      }
      
    },
     formatTooltip(val) {
        return val / 100;
      },
    onInputValueChange(value) {
      this.inputValue = value;
    },
   
    //提交信息
    personCardSort(evnets) {

       if(!this.picItem.url){
        this.$message({
          message: '请上传图片~',
          type: 'warning'
        });
        this.$refs.chatContent.lgg()
        return
       }


     


       
       if(this.scale==4){
       if(!this.made_width){
        this.$message({
          message: '请填写自定义宽度~',
          type: 'warning'
        });
        this.$refs.chatContent.lgg()
        return
       } 

       if(!this.made_hight){
        this.$message({
          message: '请填写自定义高度~',
          type: 'warning'
        });
        this.$refs.chatContent.lgg()
        return
       } 

        this.custom_scale=this.made_width+":"+this.made_hight
       }
      
      
       // this.chatWindowInfo=""   
      // this.$refs.chatContent.lgg({name:'ok'})
      let obj_data={
        prompt:{
          positive: this.ForwardValue,
          negative:this.ReverseValue,
          do_optimize: this.IsForward,
          use_negative: this.IsReverse
        },
        basic: {
          scale:this.scale=='4'?'自定义':this.scale,
          custom_scale:this.custom_scale,
          quality:this.quality,
          quantity: this.quantity,
        },
        advanced: {
          seed_num: this.seed_num,
          step_num: this.step_num,
          constraint_strength: this.constraint_strength
        },
        model_id:1,
        controlnet_action:evnets.controlnet_action,
        picture_id: this.picItem.id
      }     

       if(!obj_data.prompt.positive){
        this.$message({
          message: '请输入提示词~',
          type: 'warning'
        });
        this.$refs.chatContent.lgg()
        return
      }

     
      getPic(obj_data).then((res) => {
        if(res.ok){
          this.messages.push(res.data);
          this.chatWindowInfo=res.data
           this.seed_num=''
          // this.saveMessages();
        }else{
          this.$message({
              message: res.msg,
              type: 'warning'
          });
          this.$refs.chatContent.lgg()
          return
        }        
      }).catch(error => {
        // 状态码非200，处理错误
        // console.error('请求失败:', error);
        this.$message({
              message: error.response.data.msg+'，请联系客服~' ,
              type: 'warning'
          });
         this.seed_num=''
        this.$refs.chatContent.lgg()
        // 可以在这里进行错误处理，例如提示用户或重试等策略
      });

    },
    //存储信息
    saveMessages() {
      localStorage.setItem('chatImageHistory', JSON.stringify(this.messages));
    },
     //获取信息
    onInputValueChange(events,type){
      if(events.msgtyp ==1) this.ForwardValue=events.value
      if(events.msgtyp ==2) this.ReverseValue=events.value
    },

       //比例
    scaleClick(event){
      this.scale=event;
      if(event==4){
        this.scale=event;
        // this.open()
      }else{
        this.scale=event;
      }
    },
    //画质
    QualityClick(events){
      this.quality=events;
    },    
    //数量
    QuantityClick(event){
      this.quantity=event;
    },
    //自定比例
    open() {
        this.$prompt('请输入比例', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          this.custom_scale=value
          // this.$message({
          //   type: 'success',
          //   message: '你的邮箱是: ' + value
          // });
        }).catch(() => {
             
        });
      }


  },
};
</script>

<style lang="scss" scoped>

:deep(.el-input .el-input__inner){
    width: 135px;
    height: 35px;
  opacity: 1;
  border-radius: 20px;
  background: rgba(42, 41, 46, 1);
  color: rgba(255, 255, 255, 1);
  border: none; /* 去除边框 */
  outline: none; 
}

.make_class{
  display: flex;
  flex-direction: row;
  align-content: center;
  text-align: center;
  justify-content: center;
   width: 50%;
}

:deep(.el-upload-dragger) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-top: -20px !important;

}
:deep(.avatar) {
  // margin-left: auto;
  // margin-right: auto;
  // margin-top: 35px;
  // margin-bottom: -45px;
  width: 42px;
  height: 35px;
  // display: block;
}

:deep(.el-switch__core:after){
  top: 1x;
  width: 15px;
  height: 15px;
}

/* 滑块被拖动时的背景色 */
:deep(.el-slider__bar) {
  background-color: rgba(121, 72, 234, 1); /* 这里设置为红色，你可以改为你想要的颜色 */
}
 
/* 如果你想要改变滑块被拖动时的边框颜色，可以添加以下代码 */
:deep(.el-slider__button ) {
   border-color: rgba(121, 72, 234, 1); /* 这里设置为红色，你可以改为你想要的颜色 */
}

.step_sz:focus  {
  outline: none;
  border: 1px solid rgba(121, 72, 234, 1); /* 设置获取焦点时的边框颜色 */
}



:deep(.el-upload-dragger){
  height:135px !important;
  width: 390px;
  margin-top: -50px;
  background: none !important;
  border: none !important;
}


.image_text{
  font-size: 16px;
font-weight: 400;
letter-spacing: 0px;
line-height: 21.95px;
color: rgba(128, 128, 128, 1);
text-align: left;
vertical-align: top;
margin-left: 15px;
}
.chat_image{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 390px;
  // height: 132px;
  height: 150px;
  opacity: 1;
  border-radius: 10px;
  
  
  margin: 10px 20px 15px 20px;
  border: 2px dashed rgba(42, 41, 46, 1);

}
.step_sz{
  width: 80px;
  height: 35px;
  opacity: 1;
  border-radius: 6px;
  background: rgba(42, 41, 46, 1);
  color: rgba(255, 255, 255, 1);
  border: none; /* 去除边框 */
  outline: none; /* 去除焦点时的轮廓 */
  // color: #fff;
  text-align: center;
  font-size: 15px;
}
.step_number_{
 width: 100%;
}

.my-custom-class{
  // border: 1px solid rgba(187, 158, 255, 0.28) !important;
  background-image: url("@/assets/img/model_selected.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.my-custom-class1{
  // border: 1px solid rgba(187, 158, 255, 0.28) !important;
  background-image: url("@/assets/img/model_selected1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.step_number{
  margin-left: 15px;
  width: 60px;
}
.block{
  widows: 120px;
}
.set_title{
  margin-left:20px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(229, 229, 229, 1);
}
.optimize{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-top: 10px;
  margin-right: 20px;
}
.optimize—left{
   display: flex;
   width: 100%;
  flex-direction: row;
  align-items: center;
  text-align: center;
}
.optimize—left span{
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21.95px;
  color: rgba(229, 229, 229, 1);
  margin-right:8px;
}
.optimize—step{
   display: flex;
  flex-direction: column;
   width: 100%;
  // align-items: center;
  // text-align: center;
}
.optimize—step span{
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21.95px;
  color: rgba(229, 229, 229, 1);
  margin-right:8px;
}
.optimize—image{
  width: 12px;
  height: 12px;
}
.top_mian1{
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 430px;
  padding-bottom: 20px;
  padding-top: 12px;
  opacity: 1;
  border-radius: 12px;
  background: rgba(24, 23, 26, 1);
}
.base_image{
  width: 78.45px;
  height: 33.22px;
}
.chatHome {
  // margin-top: 20px;
  display: flex;
  .chatLeft {
    width: 430px;
    margin-top: -6px;
    .title {
      color: #fff;
      padding-left: 10px;
    }
    .online-person {
      margin-top: 100px;
      .onlin-text {
        padding-left: 10px;
        color: rgb(176, 178, 189);
      }
      .person-cards-wrapper {
        padding-left: 10px;
        height: 65vh;
        margin-top: 20px;
        overflow: hidden;
        overflow-y: scroll;
        box-sizing: border-box;
        &::-webkit-scrollbar {
          width: 0; /* Safari,Chrome 隐藏滚动条 */
          height: 0; /* Safari,Chrome 隐藏滚动条 */
          display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
        }
      }
    }
  }

  .chatRight {
    flex: 1;
    padding-right: 30px;
    .showIcon {
      position: absolute;
      top: calc(50% - 150px); /*垂直居中 */
      left: calc(50% - 50px); /*水平居中 */
      .icon-snapchat {
        width: 300px;
        height: 300px;
        font-size: 300px;
        // color: rgb(28, 30, 44);
      }
    }
  }
}
</style>