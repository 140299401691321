<template>
  <div class="chat-window">
    <div class="top" style="border-radius: 12px;background: rgba(24, 23, 26, 1);border: 1.4px solid rgba(56, 56, 56, 1);">
       <div class="model_select">
         <img src="@/assets/img/upload_one.png" style="width: 128px;height: 30px;">
       </div>
      <div style="display:flex;margin-top:5px;margin-left:20px">
          <el-upload class="image-uploader"
            action="/api/upload_picture?scene=lora"
            list-type="picture-card"
            multiple
            name="file"
            :limit="20"
            accept=".png,.jpg,.jpeg,.bmp" 
            :on-preview="handlePictureCardPreview"
            :on-success="handleSuccess"
            :on-remove="handleRemove">
            
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else  class="el-icon-plus"></i>
            <span class="add_image">添加图片 （{{images}}/20）</span>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>  
        
        
        
      </div>
      <div class="model_select2">
        <div style="display: flex; align-items: center;">
          <img src="@/assets/img/upload_two.png" style="width: 144px;height: 30px;">
          <el-popover   offset="10"   placement="top-start"  popper-class="popoverStyle"    title=""    width="300"    trigger="hover"    content="根据上传图片的统一风格，输入一个触发关键词">   
             <img class="optimize—image" src="@/assets/img/optimize.png" style="" slot="reference">
          </el-popover>
        </div>
          
          <div style="margin-top: 10px;">
            <input type="text" name="" id=""
              class="paper-textarea"
              :placeholder="placeholder"            
              v-model="textAreaValue"
              style="padding-left: 15px;"            
            >
          </div>

          <div class="sub_exercise" @click="LoraList">
            
            <img src="@/assets/img/exercise.png" style=" width: 143px;height: 45px;">

          </div>
       </div>      
    </div>
  </div>
</template>

<script>
import { animation } from "@/util/util";
import { getLoraTrain } from "@/api/getData";

import HeadPortrait from "@/components/HeadPortrait";
import Emoji from "@/components/Emoji";
import FileCard from "@/components/FileCard.vue";
export default {
  components: {
    HeadPortrait,
    Emoji,
    FileCard,
  },
  props: {
    frinedInfo: Object,
    default() {
      return {};
    },
  },
  data() {
    return {
      fileList:[],
      dialogImageUrl: '',
      dialogVisible: false,
      placeholder:"请根据上述图片，定义一个触发词",
      textAreaValue:'',
      lora_list:[],
      imageUrl:require("@/assets/img/image_default.png"),
      images:20
    };
  },
  mounted() {    
    this.LoraTrainlist();
  },
  methods: {
    
     //获取本地信息
     LoraTrainlist(){
      // localStorage.removeItem('chatHistory');
      const chatLoraHistory = localStorage.getItem('chatLoraHistory');
      if (chatLoraHistory) {
        try {
          this.lora_list = JSON.parse(chatLoraHistory);
        } catch (e) {
          // localStorage.removeItem('chatHistory');
        }
      }
    },
    LoraList(){
      let obj_data={
        trigger_word:this.textAreaValue,
        model_id:0,
      }

     if(!this.fileList.length>0){
        this.$message({
          message: '请上传图片信息~',
          type: 'warning'
        });
      return
     }
     if(!obj_data.trigger_word){
        this.$message({
          message: '请填写触发词~',
          type: 'warning'
        });
      return
     }
    let picture_ids=[]
     for(let i = 0; i < this.fileList.length; i++ ){
         picture_ids.push(this.fileList[i].id)
     }
     obj_data.picture_ids=picture_ids
     getLoraTrain(obj_data).then((res) => {
        if(res.ok){
          this.lora_list.push(res.data);
          this.saveLoraTrain()
          this.$router.push({
            name: "LoraList",
          }, () => {});
        } else {
          this.$message({
              message: res.msg,
              type: 'warning'
          });
          this.$refs.chatContent.lgg()
          return

        }
       
     })
    },
    saveLoraTrain() {
      localStorage.setItem('chatLoraHistory', JSON.stringify(this.lora_list));
    },

    handleRemove(res) {     
        let fileList = this.fileList
        let file_List=[];
        for (let i = 0; i < fileList.length; i++) {
            if(fileList[i].id!=res.response.data.id) file_List.push(fileList[i])
        }
        this.fileList=file_List      
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSuccess(res){
      if(res.ok){
        this.fileList.push(res.data);
        this.images=20- this.fileList.length
      }
    },

    
  },
};
</script>

<style lang="scss" scoped>


.el-popper[x-placement^=top] .popper__arrow::after{
    border-top-color: rgba(231, 221, 237, 1);
  }


.optimize—image{
  margin-top: -15px;
  margin-left: 10px;
  width: 12px;
  height: 12px;
}
:deep(.add_image){
  height: 85px;
  margin-top: -50px;
  margin-left: 10px;
  font-size: 13.06px;
font-weight: 400;
letter-spacing: 0px;
// line-height: 17.92px;
color: rgba(92, 91, 120, 1);
text-align: center;
vertical-align: top;
}
:deep(.el-upload--picture-card){
  display: block;  
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  
  background: rgba(31, 29, 34, 1);
  border: 2.63px dashed rgba(42, 41, 46, 1);

}
:deep(.avatar) {
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  margin-bottom: -45px;
  width: 38px;
  height: 33px;
  display: block;
}
.sub_exercise{
  display: block;
  float: right;
  margin-top: 140px;
  margin-right: 20px;
  margin-bottom: 40px;
 
}
.paper-textarea-warpper {
  border: none !important;
  position: relative;
  width: 375px;
  height: 125px;
    margin: 0px 20px 15px 20px;
  background: rgba(42, 41, 46, 1);
  padding: 10px; /* 设置内边距为10px */
  border-radius: 4px;
  .paper-length-maxlength {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 13px;
    color: #bababa;
  }
}
.paper-textarea {
  border: none;
  width: 26%;
  height: 40px;
 background: rgba(42, 41, 46, 1);
  /** 禁止textarea拉伸 */
  resize: none;

  font-size: 16px;
font-weight: 400;
letter-spacing: 0px;
line-height: 21.95px;
color: rgba(128, 128, 128, 1);

border-radius: 10px;

  /* WebKit, Blink, Edge */
  &::-webkit-input-placeholder {
    color: #bababa;
  }
  /* Mozilla Firefox 4 to 18 */
  &:-moz-placeholder {
    color: #bababa;
  }
  /* Mozilla Firefox 19+ */
  &::-moz-placeholder {
    color: #bababa;
  }
  /* Internet Explorer 10-11 */
  &:-ms-input-placeholder {
    color: #bababa;
  }
}

/* 修改placeholder的样式 */
.cpaper-textarea::-webkit-input-placeholder {
  padding:5px;
    white-space: pre-wrap;
 font-size: 13px;
  line-height: 1.8;
  color: #909090;
}
 
.paper-textareacustom-textarea::-moz-placeholder {
   padding:5px;
    white-space: pre-wrap;
 font-size: 13px;
  line-height: 1.8;
  color: #909090;
}
 
.paper-textarea:-ms-input-placeholder {
   padding:5px;
    white-space: pre-wrap;
 font-size: 13px;
  line-height: 1.8;
  color: #909090;
}
 
.paper-textarea::placeholder {
   padding:5px;
    white-space: pre-wrap;
  font-size: 13px;
  line-height: 1.8;
  color: #909090;
}

:deep(.image-uploader){
  display: flex;
}

.image-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
 
.image-uploader .el-upload:hover {
  border-color: #409EFF;
}
 


.el-upload{
  background-color: rgba(31, 29, 34, 1) !important;
}
.el-upload--picture-card{
  background-color: rgba(31, 29, 34, 1) !important;
}

.van-uploader__upload {
  width: 160px!important;
  height: 160px!important;
}

.van-uploader__preview-image{
  width: 160px!important;
  height: 160px!important;
}


.van-uploader{
  width: 160px !important;
  height: 160px !important;
}

.chat-window {
  width: 100%;
  height: 100%;
  margin-left: 20px;
  position: relative;

  .top {
    margin-bottom: 15px;
    min-height: 90vh;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
    .head-pic {
      float: left;
    }
    .info-detail {
      float: left;
      margin: 5px 20px 0;
      .name {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
      }
      .detail {
        color: #9e9e9e;
        font-size: 12px;
        margin-top: 2px;
      }
    }
    .other-fun {
      float: right;
      margin-top: 20px;
      span {
        margin-left: 30px;
        cursor: pointer;
      }
      // .icon-tupian {

      // }
      input {
        display: none;
      }
    }
  }
  .botoom {
    width: 100%;
    height: 68vh;
    background-image: url("@/assets/img/chat-image.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
     //background: conic-gradient(from 90deg at 50% 50%, rgba(0, 81, 255, 0) 12.07%, rgba(77, 0, 255, 0) 54.68%, rgba(106, 43, 252, 0.87) 89.69%);
    //  filter: blur(10px);
    border: 3px solid rgb(38, 22, 74);
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    .chat-content {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding: 20px;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        width: 0; /* Safari,Chrome 隐藏滚动条 */
        height: 0; /* Safari,Chrome 隐藏滚动条 */
        display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
      }
      .chat-wrapper {
        position: relative;
        word-break: break-all;
        .chat-friend {
          width: 100%;
          float: left;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          .chat-text {
            max-width: 90%;
            padding: 20px;
            border-radius: 20px 20px 20px 5px;
            background-color: rgb(56, 60, 75);
            color: #fff;
            &:hover {
              background-color: rgb(39, 42, 55);
            }
          }
          .chat-img {
            img {
              width: 100px;
              height: 100px;
            }
          }
          .info-time {
            display: block;
            width: 100%;
            margin: 10px 0;
            color: #fff;
            font-size: 14px;
            img {
              width: 30px;
              height: 30px;
              // border-radius: 50%;
              vertical-align: middle;
              margin-right: 10px;
            }
            span:last-child {
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 21.95px;
              color: rgba(229, 229, 229, 1);
              text-align: left;
              vertical-align: top;
            }
          }
        }
        .chat-me {
          width: 100%;
          float: left;
          margin-bottom: 20px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          .chat-text {
            float: right;
            max-width: 90%;
            padding: 20px;
            border-radius: 20px 20px 5px 20px;
            background-color: rgb(29, 144, 245);
            color: #fff;
            &:hover {
              background-color: rgb(26, 129, 219);
            }
          }
          .chat-img {
            img {
              max-width: 300px;
              max-height: 200px;
              border-radius: 10px;
            }
          }
          .info-time {
            margin: 10px 0;
            color: #fff;
            font-size: 14px;
            display: flex;
            justify-content: flex-end;

            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              vertical-align: middle;
              margin-left: 10px;
            }
            span {
              line-height: 30px;
            }
            span:first-child {
              color: rgb(101, 104, 115);
              margin-right: 10px;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .chatInputs {
      width: 90%;
      position: absolute;
      bottom: 0;
      margin: 3%;
      display: flex;
      .boxinput {
        width: 50px;
        height: 50px;
        background-color: rgb(66, 70, 86);
        border-radius: 15px;
        border: 1px solid rgb(80, 85, 103);
        position: relative;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .emoji {
        transition: 0.3s;
        &:hover {
          background-color: rgb(46, 49, 61);
          border: 1px solid rgb(71, 73, 82);
        }
      }

      .inputs {
        width: 90%;
        height: 50px;
        background-color: rgb(66, 70, 86);
        border-radius: 15px;
        border: 2px solid rgb(34, 135, 225);
        padding: 10px;
        box-sizing: border-box;
        transition: 0.2s;
        font-size: 20px;
        color: #fff;
        font-weight: 100;
        margin: 0 20px;
        &:focus {
          outline: none;
        }
      }
      .send {
        background-color: rgb(29, 144, 245);
        border: 0;
        transition: 0.3s;
        box-shadow: 0px 0px 5px 0px rgba(0, 136, 255);
        &:hover {
          box-shadow: 0px 0px 10px 0px rgba(0, 136, 255);
        }
      }
    }
  }
}

.model_select{
  display: flex;
  margin-left: 15px;
  margin-top: 15px;
}

.model_select2{
   margin-top: 260px;
   margin-left: 15px;
}
.model{
  display: flex;
  flex-direction: column;
  width: 287px;
  align-content: center;
  justify-content: center;
  text-align: center;
  // height: 145px;
  opacity: 1;
  border-radius: 10px;
  border: 1px solid rgba(99, 90, 117, 1);
  margin-bottom: 10px;
  margin-right: 10px;
}
.model1{
  display: flex;
  flex-direction: column;
  width: 190px;
  align-content: center;
  justify-content: center;
  text-align: center;
  height: 162px;
  opacity: 1;
  border-radius: 10px;
  border: 1px solid rgba(99, 90, 117, 1);
  margin-bottom: 10px;
  margin-right: 10px;
  background-image: url("@/assets/img/model1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.model2{
  display: flex;
  flex-direction: column;
  width: 190px;
  align-content: center;
  justify-content: center;
  text-align: center;
  height: 162px;
  opacity: 1;
  border-radius: 10px;
  border: 1px solid rgba(99, 90, 117, 1);
  margin-bottom: 10px;
  margin-right: 10px;
  background-image: url("@/assets/img/model2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.model3{
  display: flex;
  flex-direction: column;
  width: 190px;
  align-content: center;
  justify-content: center;
  text-align: center;
  height: 162px;
  opacity: 1;
  border-radius: 10px;
  border: 1px solid rgba(99, 90, 117, 1);
  margin-bottom: 10px;
  margin-right: 10px;
  background-image: url("@/assets/img/model3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}



.model4{
  display: flex;
  flex-direction: column;
  width: 190px;
  align-content: center;
  justify-content: center;
  text-align: center;
  height: 162px;
  opacity: 1;
  border-radius: 10px;
  border: 1px solid rgba(99, 90, 117, 1);
  margin-bottom: 10px;
  margin-right: 10px;
  background-image: url("@/assets/img/model4.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.model5{
  display: flex;
  flex-direction: column;
  width: 190px;
  align-content: center;
  justify-content: center;
  text-align: center;
  height: 162px;
  opacity: 1;
  border-radius: 10px;
  border: 1px solid rgba(99, 90, 117, 1);
  margin-bottom: 10px;
  margin-right: 10px;
  background-image: url("@/assets/img/model5.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


.monde_active{
  border: 1px solid rgba(223, 207, 255, 1);
}

.model_title{
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21.95px;
  color: rgba(196, 187, 242, 1);
  text-align: left;
  width: 100%;
  margin-left: 15px;
  margin-top: -50px;
  padding-bottom: 6px;

}
.model_main{
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 2px solid rgba(42, 41, 46, 1);
}
.model_text{
  display: flex;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(151, 145, 184, 1);
  margin-left: 15px;
}
.model_submit{
  text-align: center;
  align-content: center;
  display: flex;
  position: absolute;
  right: 10px;
  margin-top:40px
}
.send_time{
  float: left;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 19.21px;
  color: rgba(229, 229, 229, 0.7);
  text-align: left;
  vertical-align: top;
}

.send_image{
  float: right;
}
.chat-img{
  display: flex;
  flex-direction: row;
}
.empty_text{
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 15px;
  line-height: 24.7px;
  // color: rgba(255, 255, 255, 1);
  // color: linear-gradient(to right, red, blue);
  text-align: left;
  vertical-align: top;
   /* 创建一个水平方向的颜色渐变 */
   background: linear-gradient(120deg, rgba(121, 107, 249, 1) 0%,rgba(40, 103, 248, 1) 49.57%, rgba(7, 213, 253, 1));
    /* 将文本透明度设置为0，以便背景渐变可见 */
    color: transparent;
    /* 使用背景渐变来填充文本背景 */
    -webkit-background-clip: text;
    background-clip: text;
}
.chat-empty{
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如果也需要水平居中的话 */
  height: 58vh; /* 父元素高度，根据实际情况设置 */
}
.empty_info{
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>