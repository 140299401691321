<template>
  <div class="chat-window">
    <div class="top" style="border-radius: 12px;background: rgba(24, 23, 26, 1);border: 1.4px solid rgba(56, 56, 56, 1);">
       <div class="model_select">
         <img src="@/assets/img/model_select1.png" style="width: 127px;height: 32px;">
       </div>
      <div style="display:flex;margin-top:5px;margin-left:20px">
          <div class="model1 monde_active">
             <span class="model_title">线条学习</span>
             <span class="model_text">提取轮廓进行重绘</span>
          </div>

          <div class="model2">
             <span class="model_title">亮度控制</span>
             <span class="model_text">匹配参考图亮度</span>
          </div>

          <div class="model3">
             <span class="model_title">姿势迁移</span>
             <span class="model_text">提取骨骼动作</span>
          </div>

          <div class="model4">
             <span class="model_title">深度迁移</span>
             <span class="model_text">保留结构和层次细节</span>
          </div>

          <div class="model5">
             <span class="model_title">线稿学习</span>
             <span class="model_text">局部细节更加丰富</span>
          </div>
          <van-uploader :file-list="fileList" bind:after-read="afterRead" />


          

          
          <div class="model_submit">
             <img src="@/assets/img/model_submit.png" style="width: 132px;height: 40px;">
          </div>
      </div>
    
      
    </div>
    <div class="botoom">
      <div class="chat-content" ref="chatContent">
        <div class="chat-wrapper" v-if="images" v-for="item in chatList" :key="item.id">          
          <div class="chat-friend">
            <div class="info-time">
              <span class="send_time">{{ item.time }}</span>
              <div class="send_image" >
                <img src="@/assets/img/download.png" style="width: 16px;;height: 16px;">
                <img src="@/assets/img/middle.png" style="width: 1px;height: 12px;">
                <img src="@/assets/img/deal_msg.png" style="width: 16px;height: 16px;">                
              </div>            
            </div>
            <div class="info-time">
              <span>{{ item.send_msg }}</span>            
            </div>            
            <div class="chat-img">
              <div class="word-file" v-for="images in item.msg" :key="images">
                   <img :src="images" style="width: 269px;height: 202px; padding-right: 10px;">
              </div>
            </div>            
          </div>
        </div>
        <div class="chat-empty" v-if="!images">
          <div class="empty_info" >
            <img src="@/assets/img/empty.png" alt=""  style="width: 120px;height: 120px;"/>
            <span class="empty_text">让无处不在的灵感随时落地吧～</span>
          </div>
        </div>
      </div>
      <!-- <div class="chatInputs">
        <div class="emoji boxinput" @click="clickEmoji">
          <img src="@/assets/img/emoji/smiling-face.png" alt="" />
        </div>
        <div class="emoji-content">
          <Emoji
            v-show="showEmoji"
            @sendEmoji="sendEmoji"
            @closeEmoji="clickEmoji"
          ></Emoji>
        </div>
        <input class="inputs" v-model="inputMsg" @keyup.enter="sendText" />
        <div class="send boxinput" @click="sendText">
          <img src="@/assets/img/emoji/rocket.png" alt="" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { animation } from "@/util/util";
import { getChatMsg } from "@/api/getData";

import HeadPortrait from "@/components/HeadPortrait";
import Emoji from "@/components/Emoji";
import FileCard from "@/components/FileCard.vue";
export default {
  components: {
    HeadPortrait,
    Emoji,
    FileCard,
  },
  props: {
    frinedInfo: Object,
    default() {
      return {};
    },
  },
  watch: {
    frinedInfo() {
      this.getFriendChatMsg();
    },
  },
  data() {
    return {
      chatList: [],
      inputMsg: "",
      showEmoji: false,
      friendInfo: {},
      srcImgList: [],
      images:true,
      fileList:[]
    };
  },
  mounted() {
    this.getFriendChatMsg();
  },
  methods: {
    //获取聊天记录
    getFriendChatMsg() {
      let params = {
        frinedId: '1002',
      };
      getChatMsg(params).then((res) => {
        this.chatList = res;
        this.chatList.forEach((item) => {
          if (item.chatType == 2 && item.extend.imgType == 2) {
            this.srcImgList.push(item.msg);
          }
        });
    this.scrollBottom();

      });
    },
    //发送信息
    sendMsg(msgList) {
      this.chatList.push(msgList);
      this.scrollBottom();
    },
    //获取窗口高度并滚动至最底层
    scrollBottom() {
      this.$nextTick(() => {
        const scrollDom = this.$refs.chatContent;
        animation(scrollDom, scrollDom.scrollHeight - scrollDom.offsetHeight);
      });
    },
    //关闭标签框
    clickEmoji() {
      this.showEmoji = !this.showEmoji;
    },
    //发送文字信息
    sendText() {
      if (this.inputMsg) {
        let chatMsg = {
          headImg: require("@/assets/img/head_portrait.jpg"),
          name: "大毛是小白",
          time: "09：12 AM",
          msg: this.inputMsg,
          chatType: 0, //信息类型，0文字，1图片
          uid: "1001", //uid
        };
        this.sendMsg(chatMsg);
        this.$emit('personCardSort', this.frinedInfo.id)
        this.inputMsg = "";
      } else {
        this.$message({
          message: "消息不能为空哦~",
          type: "warning",
        });
      }
    },
    //发送表情
    sendEmoji(msg) {
      let chatMsg = {
        headImg: require("@/assets/img/head_portrait.jpg"),
        name: "大毛是小白",
        time: "09：12 AM",
        msg: msg,
        chatType: 1, //信息类型，0文字，1图片
        extend: {
          imgType: 1, //(1表情，2本地图片)
        },
        uid: "1001",
      };
      this.sendMsg(chatMsg);
      this.clickEmoji();
    },
    //发送本地图片
    sendImg(e) {
      let _this = this;
      let chatMsg = {
        headImg: require("@/assets/img/head_portrait.jpg"),
        name: "大毛是小白",
        time: "09：12 AM",
        msg: "",
        chatType: 1, //信息类型，0文字，1图片, 2文件
        extend: {
          imgType: 2, //(1表情，2本地图片)
        },
        uid: "1001",
      };
      let files = e.target.files[0]; //图片文件名
      if (!e || !window.FileReader) return; // 看是否支持FileReader
      let reader = new FileReader();
      reader.readAsDataURL(files); // 关键一步，在这里转换的
      reader.onloadend = function() {
        chatMsg.msg = this.result; //赋值
        _this.srcImgList.push(chatMsg.msg);
      };
      this.sendMsg(chatMsg);
      e.target.files = null;
    },
    //发送文件
    sendFile(e) {
      let chatMsg = {
        headImg: require("@/assets/img/head_portrait.jpg"),
        name: "大毛是小白",
        time: "09：12 AM",
        msg: "",
        chatType: 2, //信息类型，0文字，1图片, 2文件
        extend: {
          fileType: "", //(1word，2excel，3ppt，4pdf，5zpi, 6txt)
        },
        uid: "1001",
      };
      let files = e.target.files[0]; //图片文件名
      chatMsg.msg = files;
      if (files) {
        switch (files.type) {
          case "application/msword":
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            chatMsg.extend.fileType = 1;
            break;
          case "application/vnd.ms-excel":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            chatMsg.extend.fileType = 2;
            break;
          case "application/vnd.ms-powerpoint":
          case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            chatMsg.extend.fileType = 3;
            break;
          case "application/pdf":
            chatMsg.extend.fileType = 4;
            break;
          case "application/zip":
          case "application/x-zip-compressed":
            chatMsg.extend.fileType = 5;
            break;
          case "text/plain":
            chatMsg.extend.fileType = 6;
            break;
          default:
            chatMsg.extend.fileType = 0;
        }
        this.sendMsg(chatMsg);
        e.target.files = null;
      }
    },
    // 发送语音
    telephone() {
      this.$message("该功能还没有开发哦，敬请期待一下吧~🥳");
    },
    //发送视频
    video() {
      this.$message("该功能还没有开发哦，敬请期待一下吧~🥳");
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-window {
  width: 100%;
  height: 100%;
  margin-left: 20px;
  position: relative;

  .top {
    margin-bottom: 15px;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
    .head-pic {
      float: left;
    }
    .info-detail {
      float: left;
      margin: 5px 20px 0;
      .name {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
      }
      .detail {
        color: #9e9e9e;
        font-size: 12px;
        margin-top: 2px;
      }
    }
    .other-fun {
      float: right;
      margin-top: 20px;
      span {
        margin-left: 30px;
        cursor: pointer;
      }
      // .icon-tupian {

      // }
      input {
        display: none;
      }
    }
  }
  .botoom {
    width: 100%;
    height: 68vh;
    background-image: url("@/assets/img/chat-image.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
     //background: conic-gradient(from 90deg at 50% 50%, rgba(0, 81, 255, 0) 12.07%, rgba(77, 0, 255, 0) 54.68%, rgba(106, 43, 252, 0.87) 89.69%);
    //  filter: blur(10px);
    border: 3px solid rgb(38, 22, 74);
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    .chat-content {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding: 20px;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        width: 0; /* Safari,Chrome 隐藏滚动条 */
        height: 0; /* Safari,Chrome 隐藏滚动条 */
        display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
      }
      .chat-wrapper {
        position: relative;
        word-break: break-all;
        .chat-friend {
          width: 100%;
          float: left;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          .chat-text {
            max-width: 90%;
            padding: 20px;
            border-radius: 20px 20px 20px 5px;
            background-color: rgb(56, 60, 75);
            color: #fff;
            &:hover {
              background-color: rgb(39, 42, 55);
            }
          }
          .chat-img {
            img {
              width: 100px;
              height: 100px;
            }
          }
          .info-time {
            display: block;
            width: 100%;
            margin: 10px 0;
            color: #fff;
            font-size: 14px;
            img {
              width: 30px;
              height: 30px;
              // border-radius: 50%;
              vertical-align: middle;
              margin-right: 10px;
            }
            span:last-child {
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 21.95px;
              color: rgba(229, 229, 229, 1);
              text-align: left;
              vertical-align: top;
            }
          }
        }
        .chat-me {
          width: 100%;
          float: left;
          margin-bottom: 20px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          .chat-text {
            float: right;
            max-width: 90%;
            padding: 20px;
            border-radius: 20px 20px 5px 20px;
            background-color: rgb(29, 144, 245);
            color: #fff;
            &:hover {
              background-color: rgb(26, 129, 219);
            }
          }
          .chat-img {
            img {
              max-width: 300px;
              max-height: 200px;
              border-radius: 10px;
            }
          }
          .info-time {
            margin: 10px 0;
            color: #fff;
            font-size: 14px;
            display: flex;
            justify-content: flex-end;

            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              vertical-align: middle;
              margin-left: 10px;
            }
            span {
              line-height: 30px;
            }
            span:first-child {
              color: rgb(101, 104, 115);
              margin-right: 10px;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .chatInputs {
      width: 90%;
      position: absolute;
      bottom: 0;
      margin: 3%;
      display: flex;
      .boxinput {
        width: 50px;
        height: 50px;
        background-color: rgb(66, 70, 86);
        border-radius: 15px;
        border: 1px solid rgb(80, 85, 103);
        position: relative;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .emoji {
        transition: 0.3s;
        &:hover {
          background-color: rgb(46, 49, 61);
          border: 1px solid rgb(71, 73, 82);
        }
      }

      .inputs {
        width: 90%;
        height: 50px;
        background-color: rgb(66, 70, 86);
        border-radius: 15px;
        border: 2px solid rgb(34, 135, 225);
        padding: 10px;
        box-sizing: border-box;
        transition: 0.2s;
        font-size: 20px;
        color: #fff;
        font-weight: 100;
        margin: 0 20px;
        &:focus {
          outline: none;
        }
      }
      .send {
        background-color: rgb(29, 144, 245);
        border: 0;
        transition: 0.3s;
        box-shadow: 0px 0px 5px 0px rgba(0, 136, 255);
        &:hover {
          box-shadow: 0px 0px 10px 0px rgba(0, 136, 255);
        }
      }
    }
  }
}

.model_select{
  display: flex;
  margin-left: 15px;
  margin-top: 15px;
}
.model{
  display: flex;
  flex-direction: column;
  width: 287px;
  align-content: center;
  justify-content: center;
  text-align: center;
  // height: 145px;
  opacity: 1;
  border-radius: 10px;
  border: 1px solid rgba(99, 90, 117, 1);
  margin-bottom: 10px;
  margin-right: 10px;
}
.model1{
  display: flex;
  flex-direction: column;
  width: 190px;
  align-content: center;
  justify-content: center;
  text-align: center;
  height: 162px;
  opacity: 1;
  border-radius: 10px;
  border: 1px solid rgba(99, 90, 117, 1);
  margin-bottom: 10px;
  margin-right: 10px;
  background-image: url("@/assets/img/model1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.model2{
  display: flex;
  flex-direction: column;
  width: 190px;
  align-content: center;
  justify-content: center;
  text-align: center;
  height: 162px;
  opacity: 1;
  border-radius: 10px;
  border: 1px solid rgba(99, 90, 117, 1);
  margin-bottom: 10px;
  margin-right: 10px;
  background-image: url("@/assets/img/model2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.model3{
  display: flex;
  flex-direction: column;
  width: 190px;
  align-content: center;
  justify-content: center;
  text-align: center;
  height: 162px;
  opacity: 1;
  border-radius: 10px;
  border: 1px solid rgba(99, 90, 117, 1);
  margin-bottom: 10px;
  margin-right: 10px;
  background-image: url("@/assets/img/model3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}



.model4{
  display: flex;
  flex-direction: column;
  width: 190px;
  align-content: center;
  justify-content: center;
  text-align: center;
  height: 162px;
  opacity: 1;
  border-radius: 10px;
  border: 1px solid rgba(99, 90, 117, 1);
  margin-bottom: 10px;
  margin-right: 10px;
  background-image: url("@/assets/img/model4.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.model5{
  display: flex;
  flex-direction: column;
  width: 190px;
  align-content: center;
  justify-content: center;
  text-align: center;
  height: 162px;
  opacity: 1;
  border-radius: 10px;
  border: 1px solid rgba(99, 90, 117, 1);
  margin-bottom: 10px;
  margin-right: 10px;
  background-image: url("@/assets/img/model5.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


.monde_active{
  border: 1px solid rgba(223, 207, 255, 1);
}

.model_title{
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21.95px;
  color: rgba(196, 187, 242, 1);
  text-align: left;
  width: 100%;
  margin-left: 15px;
  margin-top: -50px;
  padding-bottom: 6px;

}
.model_main{
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 2px solid rgba(42, 41, 46, 1);
}
.model_text{
  display: flex;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(151, 145, 184, 1);
  margin-left: 15px;
}
.model_submit{
  text-align: center;
  align-content: center;
  display: flex;
  position: absolute;
  right: 10px;
  margin-top:40px
}
.send_time{
  float: left;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 19.21px;
  color: rgba(229, 229, 229, 0.7);
  text-align: left;
  vertical-align: top;
}

.send_image{
  float: right;
}
.chat-img{
  display: flex;
  flex-direction: row;
}
.empty_text{
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 15px;
  line-height: 24.7px;
  // color: rgba(255, 255, 255, 1);
  // color: linear-gradient(to right, red, blue);
  text-align: left;
  vertical-align: top;
   /* 创建一个水平方向的颜色渐变 */
   background: linear-gradient(120deg, rgba(121, 107, 249, 1) 0%,rgba(40, 103, 248, 1) 49.57%, rgba(7, 213, 253, 1));
    /* 将文本透明度设置为0，以便背景渐变可见 */
    color: transparent;
    /* 使用背景渐变来填充文本背景 */
    -webkit-background-clip: text;
    background-clip: text;
}
.chat-empty{
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如果也需要水平居中的话 */
  height: 58vh; /* 父元素高度，根据实际情况设置 */
}
.empty_info{
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>